import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Trend from "./pages/bet/Trend";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import Login from "./pages/login/Login";
import Register1 from "./pages/register/Register";
import Bet from "./pages/bet/Bet";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./pages/Home";
import Set from "./pages/Set/Set";
import Request from "./pages/request/Request";
import Users from "./pages/users/Users";
import Add from "./pages/Add/Add";
import { Coundown } from "./pages/Test";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import AddMoney from "./pages/addmoneyuser/AddMoney";
import WithDraw from "./pages/addmoneyuser/WithDraw";
import Award from "./pages/addmoneyuser/Award";
import History from "./pages/addmoneyuser/History";
import HistoryBet from "./pages/addmoneyuser/HistoryBet";
import HistoryBetAll from "./pages/request/HistoryBetAll";
import AddBank from "./pages/addmoneyuser/AddBank";
import UserProfile from "./pages/UserProfile";
import ResetPassword from "./pages/addmoneyuser/ResetPassword";
import EditBank from "./pages/addmoneyuser/EditBank";
import BienDong from "./pages/addmoneyuser/BienDong";
import Rut from "./pages/addmoneyuser/Rut";
import CSKH from "./pages/cskh/cskh";
import ThongBao from "./pages/ThongBao";
function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route element={<Login />} path="/login" />
					
					<Route element={<Register1 />} path="/register" />
					
					<Route path="/" element={<PrivateRoute />}>
						<Route element={<Coundown />} path="/test" />
						<Route element={<Home />} path="/" />
						<Route element={<Bet />} path="/bet" />	
						<Route element={<Trend />} path="/trend" />	
						<Route element={<Profile />} path="/mine" />
						<Route element={<HistoryBet />} path="/historyplay" />
						<Route element={<AddMoney />} path="/addmoney" />
						<Route element={<EditBank />} path="/bank/:id" />
						<Route element={<AddBank />} path="/addbank" />
						<Route element={<History />} path="/history" />
						<Route element={<Rut />} path="/historyget" />
						<Route element={<Award />} path="/award" />
						<Route element={<BienDong />} path="/biendongsodu"/>
						<Route element={<WithDraw />} path="/withdraw" />
						<Route element={<ResetPassword />} path="/resetpassword" />
						<Route element={<CSKH />} path="/cskh" />
					</Route>
					<Route path="/admin" element={<PrivateRouteAdmin />}>
							<Route element={<Dashboard />} path="/admin" exact />
							<Route element={<Set />} path="/admin/set" exact />
							<Route element={<UserProfile />} path="/admin/user/:id" />
							<Route element={<Request />} path="/admin/request" exact />
							<Route element={<HistoryBetAll />} path="/admin/history" exact />
							<Route element={<Add />} path="/admin/add" exact  />
							<Route element={<Users />} path="/admin/users" exact />
							<Route element={<ThongBao />} path="/admin/notification" />
						</Route>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
