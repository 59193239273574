import { useEffect, useState } from "react";

function Scroll({ className, items }) {
	return (
		<marquee direction="up" height="500" className="con scroolbox">
			{items.map((item, idx) => (
				<div key={idx}>
					<div className="name">
						<h4>{item.name}</h4>
					</div>
					<div className="message">
						<p>{item.message}</p>
					</div>
				</div>
			))}
		</marquee>
	);
}

function Slide() {
	const items = [
		{
			name: "Chị Thanh",
			message:
				"Mua về dùng gần hết rồì mới đánh giá. Bỉm merries thì khỏi nói rồi. Hút thâm tốt thật.Cũng cảm ơn Merries đã tạo công ăn việc làm cho mọi người.",
		},
		{
			name: "Chị Hòa",
			message:
				"Tả này e bé dùng rất thích. Thấm hút tốt. Ko quá dày. Các mẹ nên lựa chọn tả merries này nhé.",
		},
		{
			name: "Anh Minh",
			message: "Thương hiệu uy tín. Sản phẩm chính hãng nên yên tâm tin dùng.",
		},
		{
			name: "Chị Trâm",
			message:
				"Mình mua dùnng thử tả Merries lần đầu nên chưa đánh giá được về công dụng tuy nhiên đây là nhãn hiệu tả tốt trong số các nhãn tả nên mình khá yên tâm. ",
		},
		{
			name: "Anh Lâm",
			message: "Bao bì khá xinh, chi phí cũng ổn.",
		},
		{
			name: "Anh Tùng",
			message:
				"Tã merries thấm hút tốt, bé đi tè xong sờ tã vẫn khô. Viền tã mềm mịn không làm cấn da em bé. Miếng dán dính chắc.",
		},
		{
			name: "Chị Ngọc",
			message:
				"Công việc khá đơn giản phù hợp với mẹ bỉm, ở nhà chăm con vẫn có công việc kiếm thêm ngoài ra còn có các phần quà bỉm rất ý nghĩa. Cảm ơn Merriess Việt Nam.",
		},
		{
			name: "Chị Linh",
			message: "Đánh giá 5 * cho web và nhãn hàng Merries!",
		},
		{
			name: "Chị Nguyên",
			message:
				"Ban đầu chưa biết đến Merries nhưng qua thời gian làm việc và có những phần quà bỉm để xử dụng mình thấy nhãn hàng rất uy tín công việc cũng tốt.",
		},
		{
			name: "Chị My",
			message:
				"Cảm ơn web cũng như bỉm Merries đã tạo ra công việc cho mọi người!",
		},
		{
			name: "Chị Uyên",
			message:
				"Ban đầu cũng ngờ ngờ nhưng theo làm cũng được mấy tháng rồi rất ổn định và tốt. Cái tên nói lên tất cả Merries tuyệt vời.",
		},
		{
			name: "Chị Hoa",
			message:
				"Vẫn luôn tin dùng Merries cho cả bé lớn và bé hiện tại! Mềm, mỏng, dùng mùa hè đúng chuẩn luôn.",
		},
		{
			name: "Chị Thảo",
			message:
				"Hàng đẹp mà thấm hút tốt. Ngoài ra còn tạo công việc thụ động cho rất nhiều cảm bơn Babymart và Merries!",
		},
		{
			name: "Anh Ngọc",
			message:
				"Công việc uy tín ổn định lâu dài, mấy người nhà e đều tham gia làm và có thêm khoản thu nhập. Cảm ơn công ty ạ!",
		},
		{
			name: "Chị Chi",
			message:
				"Đã tham gia làm công việc thấy cũng đơn giản và kiếm được tiền thật. Cảm ơn Babymart.",
		},
		{
			name: "Anh Hiếu",
			message:
				"Bỉm dùng thấm hút tương đối thích bé không bị hăm và ngủ ngon giấc.",
		},
		{
			name: "Anh Nam",
			message: "Đánh giá nhãn hàng và web 5 *",
		},
		{
			name: "Chị Hải",
			message:
				"Cảm ơn về công việc và những phần quà bỉm giá trị. Đã tạo ra công ăn việc làm còn có quà nhận về mãi yêu Merries !",
		},
		{
			name: "Anh Tâm",
			message:
				"Cảm ơn các bạn nhờ các bạn tôi một người không có việc làm ở nhà chăm con giờ đã có thể có thêm thu nhập trang trải cuộc sống !",
		},
		{
			name: "Chị Vui",
			message:
				"Luôn tin tưởng dùng bỉm merries từ bé đầu đến bé thứ 2. Chất lượng tốt dễ sử dụng. Mọi người nên dùng.",
		},
		{
			name: "Chị Huyền",
			message:
				"Nếu ai chưa biết vê merries thi nên lên google tìm hiểu nhé. Dùng rồi thì khỏi cần bàn về chất lượng cũng như giá thành.",
		},
		{
			name: "Anh Sơn",
			message:
				"Lúc đầu cũng sợ này sợ nọ theo babymart làm mấy tháng rồi cảm giác rất an toàn và yên tâm. Hãng merries cũng thật sự tốt.",
		},
		{
			name: "Chị Lương",
			message:
				"Hàng chính hãng, khả năng thấm hút tốt, mình mua nhiều lần rồi và sẽ tiếp tục ủng hộ ạ. Hàng thì chất lượng, cảm ơn merries nhiều nhiều nha.",
		},
		{
			name: "Chị Khuê",
			message:
				"Lúc đầu cũng không biết thông tin về hãng giờ thì vừa có việc làm vừa có thu nhập. Cảm ơn babymart!",
		},
		{
			name: "Anh Mạnh",
			message: "Cảm ơn babymart và merries !",
		},
		{
			name: "Anh Bảo",
			message:
				"Cảm ơn các bạn đã tạo công ăn việc làm cho rất nhiều người như tôi. Chúc các bạn ngày càng phát triển và thành công !",
		},
		{
			name: "Chị Nhung",
			message:
				"Đã tham gia công việc được 1 tuần rất oke. Công việc đơn giản hiệu quả. Đã rủ thêm bạn bè vào làm kiếm thêm cảm ơn babymart và bỉm merries.",
		},
		{
			name: "Chị Trà",
			message:
				"Đang thất nghiệp thì được giới thiệu công việc. Cũng sợ lừa nhưng nghe review rất tốt nên cũng tham gia đó tới giờ gần 1 tháng rồi. Cảm ơn meriesss.",
		},
		{
			name: "Anh Tiến",
			message:
				"Tã bỉm Merries chất lượng tốt, dễ sử dụng và chất liệu thân thiện cho da bé. Công việc uy tín lâu dài.",
		},
	];

	return <Scroll className="sItem" items={items} />;
}

export default Slide;
