import Footer from "../../components/Footer/Footer";
import "../home/home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import "../profile/profile.css";
import "./addmn.css";

import avt from "../../components/male.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import swal from "sweetalert";
function AddBank() {
	const [profile, setProfile] = useState(null);
	const [show, setShow] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const [bank, setBank] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		axios
			.get(`https://server.lucky68.win/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`https://server.lucky68.win/bank/getBank`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => setBank(null));
	}, []);
	const onSubmit = (data) => {
		const formData = {
			name_bank: data.name_bank,
			stk: data.stk,
			fullname: data.fullname,
			user: profile._id,
		};
		axios
			.post(`https://server.lucky68.win/bank/create`, formData)
			.then((res) => {
				swal({
					title: "Thành công",
					text: "Liên kết ngân hàng thành công",
					icon: "success",
					buttons: "Đồng ý",
				}).then(() => navigate("/mine"));
			})
			.catch((err) =>
				setError("money", {
					type: "minLength",
					message: "Lỗi giao dịch 404!",
				})
			);
	};
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Liên kết ngân hàng
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				<div className="content_profile">
					<div className="list-bank">
						{bank != null ? (
							<>
								<form
									style={{
										display: "block",
										padding: "15px",
										textAlign: "left",
									}}
									onSubmit={handleSubmit(onSubmit)}
								>
									<div>
										<div>
											<label>Tên ngân hàng</label>
											<input
												className="ipadd"
												type="text"
												defaultValue={bank[0].name_bank}
												placeholder="Nhập tên ngân hàng"
												{...register("name_bank", { required: true })}
											/>
										</div>
										<div>
											{" "}
											<label>Số tài khoản</label>
											<input
												className="ipadd"
												type="number"
												defaultValue={bank[0].stk}
												placeholder="Nhập số tài khoản"
												{...register("stk", { required: true })}
											/>
										</div>
										<div>
											{" "}
											<label>Chủ tài khoản</label>
											<input
												className="ipadd"
												type="text"
												defaultValue={bank[0].fullname}
												placeholder="Nhập họ tên người nhân"
												{...register("fullname", { required: true })}
											/>
										</div>
										<button type="submit" className="btn-submit">
											Xác nhận
										</button>
									</div>
								</form>
							</>
						) : (
							<>
								<form
									style={{
										display: "block",
										padding: "15px",
										textAlign: "left",
									}}
									onSubmit={handleSubmit(onSubmit)}
								>
									<div>
										<div>
											<label>Tên ngân hàng</label>
											<input
												className="ipadd"
												type="text"
												{...register("name_bank", { required: true })}
												placeholder="Nhập tên ngân hàng"
											/>
										</div>
										<div>
											{" "}
											<label>Số tài khoản</label>
											<input
												className="ipadd"
												type="number"
												{...register("stk", { required: true })}
												placeholder="Nhập số tài khoản"
											/>
										</div>
										<div>
											{" "}
											<label>Chủ tài khoản</label>
											<input
												className="ipadd"
												type="text"
												{...register("fullname", { required: true })}
												placeholder="Nhập họ tên người nhân"
											/>
										</div>
										<button type="submit" className="btn-submit">
											Xác nhận
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>

				<Footer profile={profile} />
			</div>
		</>
	);
}
export default AddBank;
