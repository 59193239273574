import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRef, useEffect, useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import swal from "sweetalert";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Login() {
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	useEffect(() => {
		if (login) {
			navigate("/");
		}
	}, []);
	const onSubmit = async (data) => {
		if (data.username.length < 6) {
			setError("username", {
				type: "minLength",
				message: "Username yêu cầu ít nhất 6 kí tự",
			});
		}

		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "password yêu cầu ít nhất 6 kí tự",
			});
		}
		if (data.password.length < 6 || data.username.length < 6) {
			return;
		}
		axios
			.post(`https://server.lucky68.win/auth/login`, data)
			.then((res) => {
				localStorage.setItem("user", res.data.data);
				swal({
					title: "Thông báo",
					text: "Đăng nhập thành công",
					icon: "success",
					buttons: "OK",
				}).then(() => {
					navigate("/");
				});
			})
			.catch((err) => setErr("Tên đăng nhập hoặc mật khẩu không chính xác"));
	};
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	return (
		<>
			{/*<img src={require("../../img/picture3.png")} />*/}
			<Swiper
				spaceBetween={30}
				centeredSlides={true}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				pagination={false}
				navigation={false}
				modules={[Autoplay, Pagination]}
				className="mySwiper"
			>
				<SwiperSlide>
					<img src={require("../../img/slide1.jpg")} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={require("../../img/slide2.jpg")} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={require("../../img/slide3.jpg")} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={require("../../img/slide4.jpg")} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={require("../../img/slide5.jpg")} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={require("../../img/slide6.jpg")} />
				</SwiperSlide>
			</Swiper>

			<div className="app123">
				<div className="bg"></div>

				<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
					<div className="inputs">
						<div>
							<input
								type="text"
								className="ip-lg"
								{...register("username", { required: true })}
								placeholder="Tên đăng nhập"
							/>
							{errors.username ? <p>{errors.username.message}</p> : null}
						</div>
						<div>
							<input
								type={showPassword ? "text" : "password"}
								className="ip-lg"
								{...register("password", { required: true })}
								placeholder="Mật Khẩu"
							/>
							<div onClick={toggleShowPassword} className="change-visible">
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</div>
							{errors.password ? <p>{errors.password.message}</p> : null}
						</div>
					</div>
					{err ? <p style={{ color: "red" }}>{err}</p> : null}
					<button className="btn-lg" type="submit">
						Đăng nhập
					</button>
					<p className="p-lg">
						Chưa có tài khoản? 👉
						<Link className="a-lg" to="/register">
							Đăng ký ngay
						</Link>
					</p>
				</form>
				<div className="dongtaitro">
					<h3>Tài trợ chính:</h3>
					<div className="dongtaitroboi">
						<div>
							<img src={require("../../img/logo1.png")} />
						</div>
						<div>
							<img
								src={require("../../img/logo2.png")}
								style={{ padding: "5px" }}
							/>
						</div>
						<div>
							<img
								src={require("../../img/logo3.png")}
								style={{ padding: "28px" }}
							/>
						</div>
						<div style={{ padding: "5px" }}>
							<img src={require("../../img/logo4.png")} />
						</div>
						<div>
							<img src={require("../../img/logo5.png")} />
						</div>
						<div>
							<img src={require("../../img/logo6.png")} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Login;
