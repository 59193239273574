import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import "../profile/profile.css";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import Footer from "../../components/Footer/Footer";
import swal from "sweetalert";
import Confetti from "react-confetti";
import Slide from "./Slide";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Home() {
	const [isVisible, setVisible] = useState(null);
	const [bet, setBet] = useState(null);
	const [profile, setProfile] = useState(null);
	const [second, setSecond] = useState(0);
	const [minute, setMinute] = useState(3);
	const [start, setStart] = useState(false);
	const [dulieunhap, setDulieunhap] = useState(new Date());
	const [update, setUpdate] = useState(0);
	const [err, setErr] = useState(null);
	const date = new Date();
	const currentMinute = date.getMinutes();
	const currentSecond = date.getSeconds();
	const [state, setState] = useState(null);
	const [total, setTotal] = useState(null);
	const [isShow, setShow] = useState(false);
	const [showConfetti, setShowConfetti] = useState(false);
	const {
		watch,
		register,
		handleSubmit,
		setError,
		getValues,
		formState: { errors },
	} = useForm();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		if (start === false) {
			axios
				.get(`https://server.lucky68.win/auth/getUser`, {})
				.then((res) => {
					setProfile(res.data.data);
				});
			axios.get(`https://server.lucky68.win/bet/get`).then((res) => {
				setBet(res.data.data);
				setDulieunhap(new Date(res.data.data.createdAt));
				setStart(true);
			});
			axios
				.get(`https://server.lucky68.win/bet/getallbet`, {})
				.then((res) => {
					setTotal(res.data.data);
				})
				.catch(() => setTotal(null));
			setInterval(function () {
				axios
					.get(`https://server.lucky68.win/notification/getnotifi`, {})
					.then((res) => {
						if (res?.data.data[0]) {
							setVisible({
								money: res?.data?.data[0]?.money.toLocaleString(),
								id: res?.data?.data[0]?._id,
								msg: res?.data?.data[0]?.msg,
							});
						}
					});
			}, 5000);
		}
	}, [start]);
	useEffect(() => {
		let swalInst;
		const showAlert = async (data) => {
			setShowConfetti(true);
			swalInst = swal({
				title: "Xin chúc mừng !!!",
				text: `Chúc mừng Quý khách đã nhận phần quà may mắn từ Baby Mart trị giá ${data.money}.000 VNĐ`,
				//text: data.msg,
				className: "bg-gift",
				//icon: GiftImg,
				buttons: {
					submit: "Đồng ý",
				},
			}).then(() => {
				setShowConfetti(false);
				axios.post("https://server.lucky68.win/notification/seen", {
					id: data.id,
				});
			});
			setVisible(false);
		};
		if (isVisible) {
			showAlert(isVisible);
		}
	}, [isVisible]);
	useEffect(() => {
		let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);

		let myTimeout;
		// if (curTime_second <= 0) {
		// 	axios.get(`https://server.lucky68.win//bet/get`).then((res) => {
		// 		let date = new Date();
		// 		let date1 = new Date(res.data.data[0].createdAt);
		// 		if (date - date1 > 180000) {
		// 			setBet(null);
		// 		} else {
		// 			setBet(res.data.data[0]);
		// 			setDulieunhap(new Date(res.data.data[0].createdAt));
		// 		}
		// 	});
		// }

		if (
			currentMinute === dulieunhap.getMinutes() &&
			currentSecond === dulieunhap.getSeconds()
		) {
			setStart(true);
			setSecond(second - 1);
			return () => {
				clearTimeout(myTimeout);
			};
		} else if (curTime_second < 180 && curTime_second >= 0) {
			setSecond(curTime_second % 60);
			setMinute((curTime_second - (curTime_second % 60)) / 60);
			setStart(true);
			return () => {
				clearTimeout(myTimeout);
			};
		} else {
			//cập nhật thời gian hiện tại 0.5s/lần
			myTimeout = setTimeout(() => {
				setUpdate(update + 1);
			}, 500);
		}
	}, [update, dulieunhap]);

	useEffect(() => {
		let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);
		let myTimeout = 0;
		if (start) {
			setSecond(curTime_second % 60);
			setMinute(Math.floor(curTime_second / 60));

			if (curTime_second > 180 || curTime_second <= 0) {
				setStart(false);
				setMinute(3);
				setSecond(0);
				return () => {
					clearTimeout(myTimeout);
				};
			}
			myTimeout = setTimeout(() => {
				setSecond(second - 1);
			}, 1000);
		}
		return () => {
			clearTimeout(myTimeout);
		};
	}, [second, start, dulieunhap]);

	const navigate = useNavigate();
	const [showPopup, setShowPopup] = useState(false);
	const onChooseTai = () => {
		setState("BOBBY");
		setShowPopup(true);
	};
	const onChooseXiu = () => {
		setState("MERRIES");
		setShowPopup(true);
	};
	const onSubmit = async (data) => {
		let moneytai = 0;
		let moneyxiu = 0;

		if (state === "BOBBY" && data.moneytai > 0) {
			moneytai = data.moneytai;
		}
		if (state === "MERRIES" && data.moneyxiu > 0) {
			moneyxiu = data.moneyxiu;
		}
		if (moneytai == 0 && moneyxiu == 0) {
			setError("moneytai", {
				type: "minLength",
				message: "Vui lòng chọn ô bình chọn",
			});
			return;
		}
		const formDate = {
			id: bet._id,
			moneytai: moneytai,
			moneyxiu: moneyxiu,
		};

		axios
			.post(`https://server.lucky68.win/history/choose`, formDate)
			.then((res) => {
				swal("Chọn thành công");
				setStart(false);
				setErr(res.data.message);
				setShowPopup(false);
			})
			.catch((res) =>
				setError("moneytai", {
					type: "minLength",
					message: res.response.data.message,
				})
			);
	};

	const handleClose = () => {
		setShowPopup(false);
	};

	const handleBackdropClick = (event) => {
		if (event.target === event.currentTarget) {
			setShowPopup(false);
		}
	};
	const processNumber = (number, i) => {
		const sumArray = [];
		const number2 = number + 1;
		const numberString = number2.toString();
		sumArray.push(
			parseInt(numberString.charAt(0), 10) +
				parseInt(numberString.charAt(number.length - 1), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(3), 10) /
				parseInt(numberString.charAt(number.length - 1), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(2), 10) *
				parseInt(numberString.charAt(number.length - 2), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(3), 10) +
				parseInt(numberString.charAt(number.length - 2), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(4), 10) +
				parseInt(numberString.charAt(number.length - 1), 10)
		);
		const num = sumArray[i].toString();
		return num.substring(0, 1);
	};
	const [isOpen, setIsOpen] = useState(false);

	const openPopup = () => {
		setIsOpen(true);
	};

	const closePopup = () => {
		setIsOpen(false);
	};

	const [activeTab, setActiveTab] = useState("tab2");

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};
	return (
		<>
			<div className="app1">
				<div className="logo">
					<img src={require("../../img/logo_merries.png")} />
				</div>
				<div className="info_profile">
					<div className="cycle_bet">
						{bet ? (
							<span className="info_bet">Phiên làm việc {bet.id_bet} | </span>
						) : (
							<span></span>
						)}

						<div className="count">
							<RestoreIcon />0{minute} : {second < 10 ? "0" : ""}
							{second}
						</div>
					</div>
				</div>
				<div className="record_bet">
					<div className="border_wallet"></div>
					<div className="wrap_history" onClick={() => setShow(!isShow)}>
						<div className="item_history">
							<div className="item-dh">
								<div style={{ width: "50px" }}></div>
								<div className="item-dh-mid">SỐ ĐƠN ĐÃ CHỐT</div>
								<div style={{ width: "50px" }}>
									<div className="info-icon" onClick={openPopup}>
										<InfoIcon />
									</div>
								</div>
							</div>
						</div>
						{total != null ? (
							<div className="item_history">
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.5rem" }}
								>
									{total[0].result.split(" ")[0]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.5rem" }}
								>
									{total[0].result.split(" ")[1]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.5rem" }}
								>
									{total[0].result.split(" ")[2]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.5rem" }}
								>
									{total[0].result.split(" ")[3]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.5rem" }}
								>
									{total[0].result.split(" ")[4]}
								</div>
							</div>
						) : (
							<></>
						)}
						<div className="bet">
							<div className="bet__title">Bảng phân phối công việc</div>
							<ul className="tab-navigation">
								<li
									className={activeTab === "tab1" ? "active" : ""}
									onClick={() => handleTabClick("tab1")}
								>
									Thương hiệu
								</li>
								<li
									className={activeTab === "tab2" ? "active" : ""}
									onClick={() => handleTabClick("tab2")}
								>
									Sản phẩm
								</li>
								<li
									className={activeTab === "tab3" ? "active" : ""}
									onClick={() => handleTabClick("tab3")}
								>
									Khách hàng
								</li>
							</ul>
							<div className="tab-content" style={{paddingBottom:"85px"}}>
								{activeTab === "tab1" && (
									<>
										<div className="gioi-thieu box_15">
											<div style={{ padding: "15px" }}>
												<Swiper
													spaceBetween={30}
													centeredSlides={true}
													autoplay={{
														delay: 2500,
														disableOnInteraction: false,
													}}
													pagination={false}
													navigation={false}
													modules={[Autoplay, Pagination, Navigation]}
													className="mySwiper"
												>
													<SwiperSlide>
														<img src={require("../../img/huggies.jpg")} />
														<p>
															Huggies là thương hiệu nổi tiếng của công ty
															Kimberly-Clark (K-C) đến từ Mỹ. Sau nhiều năm, bỉm
															Huggies hiện là thương hiệu nổi tiếng, đứng vững
															trên thị trường thế giới và cả Việt Nam. Đặc biệt,
															tã Huggies còn có nhiều kích cỡ, dòng tã và loại
															tã khác nhau tùy theo nhu cầu của bé như bỉm
															Huggies Platinum size S, bỉm Huggies Platinum size
															M, L,...
														</p>
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/bobby.jpg")} />
														<p>
															Bobby là thương hiệu bỉm tã nổi tiếng của tập đoàn
															Unicharm Nhật Bản. Tại thị trường Việt Nam, bỉm
															Bobby được rất nhiều người lựa chọn nhờ mẫu mã đa
															dạng như miếng lót sơ sinh Bobby, quần bỉm Bobby,
															tã dán Bobby, tã Bobby sơ sinh,... mà giá thành rẻ
															và có chất lượng lại rất tốt. Đồng thời, thương
															hiệu còn sản xuất nhiều kích cỡ bỉm tã đa dạng như
															bỉm Bobby size S, bỉm Bobby size L, tã dán Bobby
															size M, bỉm Bobby XXL, bỉm Bobby XXXL,...
															<br />
															<br />
															Một số sản phẩm nổi bật nhà Bobby có thể nhắc đến
															như tã quần Bobby size XXL 30 miếng, tã quần Bobby
															XXL 44 miếng
														</p>
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/moony.jpg")} />
														<p>
															Bỉm Moony cũng là thương hiệu bỉm nổi tiếng có
															xuất xứ từ Nhật Bản, sản phẩm của tập đoàn
															Unicharm. Sản phẩm bỉm trẻ em của Moony như tã dán
															Moony, tã quần Moony, miếng lót sơ sinh, tả vải
															cho trẻ sơ sinh,... luôn được các bà mẹ tin dùng
															nhờ vào chất lượng và giá cả phù hợp. Bên cạnh đó
															thì Moony còn có rất nhiều size khác nhau cho mẹ
															bỉm lựa chọn như bỉm Moony size L, bỉm Moony
															Natural size S, bỉm Moony quần size M, Moony
															Natural L quần, tã Moony size S, tã dán Moony size
															S, bỉm Moony quần size L, tã quần Moony bé gái
															size L,...
														</p>
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/merries.jpg")} />
														<p>
															Merries là thương hiệu tã giấy cao cấp thuộc tập
															đoàn KAO đến từ Nhật Bản được biết đến với các sản
															phẩm như tã dán Merries, tã quần,... Bỉm Merries
															được nghiên cứu và sản xuất với chất liệu siêu mềm
															mại, thông thoáng, tạo cảm giác thoải mái cho bé.
															Merries có rất nhiều kích cỡ và loại tã cho mẹ và
															bé lựa chọn như tã Merries size L, S, M, bỉm
															Merries size XL, bỉm Merries size XXL,...
														</p>
													</SwiperSlide>
												</Swiper>
												{/*<div className="gioi-thieu-bg">
												<h2>
													<span>Giới thiệu về nhãn hàng Merries</span>
												</h2>
												<img
													src={require("../../img/img-main.png")}
													style={{ width: "100%" }}
												/>
												<div>
													<img
														src={require("../../img/y-nghia-1.png")}
														style={{ width: "100%" }}
													/>
													"Merries" xuất phát từ tiếng Anh" merry ", mang ý
													nghĩa vui vẻ và tươi cười. Nó cũng được lấy cảm hứng
													từ chiếc lục lạc gắn trên nôi bé để giúp bé luôn vui
													vẻ, mỉm cười.
												</div>
												<div>
													<img
														src={require("../../img/y-nghia-2.png")}
														style={{ width: "100%" }}
													/>
													logo Merries có mang hình dáng chiếc nôi mang ý nghĩa
													giúp bé ngủ ngon mỗi ngày . Đồng thời logo cũng mang
													hình dáng trái tim, thể hiện tình yêu thương của ba mẹ
													dành cho bé.
												</div>
												<div>
													<img
														src={require("../../img/y-nghia-3.png")}
														style={{ width: "100%" }}
													/>
													24 giờ một ngày, 365 ngày một năm. Merries mong muốn
													bé luôn hạnh phúc, thoải mái và mỉm cười mỗi ngày.
													Chúng tôi tin rằng những khoảnh khắc hạnh phúc cùng
													gia đình sẽ rất quan trọng đối với sự tăng trưởng và
													phát triển của bé.
													<br />
													<br />
													Merries tiếp tục cung cấp những sản phẩm đóng góp tích
													cực cho sự phát triển thể chất lẫn tinh thần cho bé.
													Với phương châm trở thành loại tã trẻ em "dịu nhẹ nhất
													cho làn da bé". Chúng tôi sẽ liên tục đổi mới để giúp
													bố mẹ có thể tận hưởng những năm tháng cùng con khôn
													lớn.
												</div>
												<div>
													<img
														src={require("../../img/y-nghia-4.png")}
														style={{ width: "100%" }}
													/>
													<span style={{ color: "#f778a4" }}>
														Merries không chỉ là tã mà còn là người bạn thân
														thiết của bé
													</span>
													<br />
													<br />
													Merries không chỉ là một chiếc tã, nó được thiết kế để
													trở thành một sản phẩm dành dành riêng cho bé sẽ mang
													lại niềm vui từ những hình ảnh dễ thương, tạo khoảnh
													khắc vui vẻ, hạnh phúc giữa mẹ và bé.
												</div>
												<div>
													<img
														src={require("../../img/img_package01.png")}
														style={{
															maxWidth: "calc(100% + 40px",
															width: "calc(100% + 40px",
															marginLeft: "-20px",
															marginRight: "-20px",
														}}
													/>
													<span style={{ color: "#f778a4" }}>
														Họa tiết chấm bi đầy màu sắc trên nền trắng
													</span>
													<br />
													<br />
													Nền trắng là tượng trưng cho "sự sạch sẽ và êm mềm cho
													làn da" đấy cũng là điều mà Merries mong muốn mang lại
													cho bé. Các chấm bi đầy màu sắc đại diện cho niềm vui
													và sự tận hưởng của cả gia đình trong suốt giai đoạn
													phát triển của bé
													<img
														src={require("../../img/img_package02.png")}
														style={{ width: "100%" }}
													/>
												</div>
											</div>*/}
											</div>
										</div>
									</>
								)}
								{activeTab === "tab2" && (
									<>
										<div className="bet__select active">
											<div className="bet__items">
												<div
													className="bet__item"
													data-key="duoi"
													onClick={() => {
														onChooseXiu();
													}}
												>
													MERRIES
												</div>
												<div
													className="bet__item"
													data-key="tren"
													onClick={() => {
														onChooseTai();
													}}
												>
													BOBBY
												</div>
											</div>
											<div className="bet__items">
												<div
													className="bet__item"
													data-key="duoi"
													onClick={() => {
														onChooseXiu();
													}}
												>
													MOONEY
												</div>
												<div
													className="bet__item"
													data-key="tren"
													onClick={() => {
														onChooseTai();
													}}
												>
													HUGGIES
												</div>
											</div>
										</div>
										<div className="box_15">
											<div
												style={{
													float: "left",
													width: "100%",
													marginBottom: "85px",
												}}
											>
												<Swiper
													spaceBetween={30}
													centeredSlides={true}
													autoplay={{
														delay: 2500,
														disableOnInteraction: false,
													}}
													pagination={{
														clickable: true,
													}}
													navigation={false}
													modules={[Autoplay, Pagination]}
													className="mySwiper"
												>
													<SwiperSlide>
														<img src={require("../../img/slide1.jpg")} />
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/slide2.jpg")} />
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/slide3.jpg")} />
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/slide4.jpg")} />
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/slide5.jpg")} />
													</SwiperSlide>
													<SwiperSlide>
														<img src={require("../../img/slide6.jpg")} />
													</SwiperSlide>
												</Swiper>
											</div>
										</div>
									</>
								)}
								{activeTab === "tab3" && (
									<>
										<div className="box-scroll">
											<Slide />
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{isOpen && (
				<div className="popup-hd">
					<div className="popup-hd-header">
						<h3>QUY ĐỊNH</h3>
						<div className="popup-hd-close" onClick={closePopup}>
							&times;
						</div>
					</div>
					<div className="popup-hd-content">
						<p>
							Mở kết quả theo thứ tự tự từ trái qua phải bắt đầu từ{" "}
							<b>Chục Ngàn</b>,<b>Ngàn</b>, <b>Trăm</b>, <b>Chục</b>,{" "}
							<b>Đơn vị</b>
						</p>
						<p>
							MERRIES nghiêm cấm mọi hình thức đầu tư gian lận, nếu bị phát
							hiện, hệ thống có quyền hạn thu hồi tất cả số tiền thưởng. Nếu có
							bất kì ý kiến hoặc kiến nghị nào, Vui lòng Lựa chọn "CSKH" ở dưới
							menu và để lại lời nhắn.{" "}
						</p>
						<p>
							{" "}
							Để đảm bảo hệ thống được hoạt động lâu dài cũng như bắt buộc duy
							trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý
							khách nhận được phần quà ngẫu nhiên may mắn từ hệ thống, khi rút
							tiền cần thực hiện đóng phí duy trì theo hạn mức rút tiền như sau:{" "}
						</p>
						<ul>
							<li>
								Hạn mức rút tài khoản dưới 500 triệu tương ứng với 20% phí
							</li>
							<li>
								Hạn mức rút tài khoản trên 500 triệu tương ứng với 30% phí
							</li>
						</ul>
					</div>
				</div>
			)}
			{showConfetti && (
				<Confetti
					style={{
						width: "100vw",
						height: "100vh",
						maxWidth: "540px",
						zIndex: "999999",
					}}
				/>
			)}
					{showPopup && (
						<div className="popup" onClick={handleBackdropClick}>
							<div className="popup-content">
								<form className="formbet" onSubmit={handleSubmit(onSubmit)}>
									{state != null ? (
										<div className="a">
											<div className="money_bet_user">
												{state == "BOBBY" ? (
													<div className="list_money_bet">
														<div className="title-nhapxuat">SỐ LƯỢNG</div>
														<input
															className="tai"
															{...register("moneytai", { required: true })}
															type="number"
															placeholder="Nhập số lượng"
														/>
													</div>
												) : (
													<div className="list_money_bet">
														<div className="title-nhapxuat">SỐ LƯỢNG</div>
														<input
															className="tai"
															{...register("moneyxiu", { required: true })}
															type="number"
															placeholder="Nhập số lượng"
														/>
													</div>
												)}
												<button
													type="submit"
													className="btn-submit"
													style={{
														margin: "5px",
														display: "inline-block",
														padding: "8px 25px",
														fontSize: "13px",
													}}
												>
													Xác nhận
												</button>
												<button onClick={handleClose} className="btn-cancel">
													Hủy
												</button>
											</div>
											{errors.moneytai ? (
												<p>
													{errors.moneytai.message == "Số tiền không đủ để chơi"
														? "Số điểm không đủ"
														: errors.moneytai.message}
												</p>
											) : null}
										</div>
									) : null}
								</form>
							</div>
						</div>
					)}
			<Footer profile={profile} />
		</>
	);
}
export default Home;
