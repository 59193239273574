import "./footer.css";
import axios from "axios";
import { useEffect, useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Link } from "react-router-dom";
//import LiveChat from "react-livechat";

function Footer(props) {
	var profile = props.profile;
	const data = [
		{
			icon: <HomeOutlinedIcon />,
			title: "Trang chủ",
			to: "/",
		},
		{
			icon: <TrendingUpIcon />,
			title: "Hệ thống",
			to: "/trend",
		},
		{
			icon: <Person3OutlinedIcon />,
			title: "Cá nhân",
			to: "/mine",
		},
	];

	return (
		<>
			<div className="bg-menu">
				<div className="detail_id">
					{profile != null ? (
						<>
							<div className="item_id">
								ID : {Number(profile.iduser) + 3000}
							</div>
							<div style={{ fontWeight: "500" }}>
								Số điểm: <b>{props.profile.money.toLocaleString()}</b>
							</div>
							{/*<LiveChat
								license={16484763}
								onChatLoaded={(ref) =>
									setTimeout(function () {
										window.LC_API.hide_chat_window();
									}, 3000)
								}
							/>*/}
						</>
					) : null}
				</div>
				<div className="footer">
					{data.map((item, index) => (
						<div className="item-footer" key={index}>
							<Link style={{ textDecoration: "none" }} to={item.to}>
								<div className="icon_footer">{item.icon}</div>
								<div className="title_footer">{item.title}</div>
							</Link>
						</div>
					))}
					<div className="item-footer">
						<div
							style={{ cursor: "pointer" }}
							className="livechat_button"
							onClick={() => {} /*window.LC_API.open_chat_window()*/}
						>
							<Link style={{ textDecoration: "none" }} to="/cskh">
								<div className="icon_footer">
									<HeadsetMicOutlinedIcon />
								</div>
								<div className="title_footer">CSKH</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Footer;
