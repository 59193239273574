import "./cskh.css";
import { useEffect, useState } from "react";
//import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
function CSKH() {
	const [loading, setLoading] = useState(true);
	const [start, setStart] = useState(false);
	const [profile, setProfile] = useState(null);
	const [total, setTotal] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	{
		/*useEffect(() => {
		const initLiveChat = () => {
			if (window.LC_API) {
				window.LC_API.on_after_load = () => {
					window.LC_API.open_chat_window();
					setLoading(false);
				};
			} else {
				setTimeout(initLiveChat, 1);
			}
		};
		initLiveChat();
	}, []);*/
	}
	useEffect(() => {
		if (start === false) {
			axios
				.get(`https://server.lucky68.win/auth/getUser`, {})
				.then((res) => {
					setProfile(res.data.data);
				});
			axios
				.get(`https://server.lucky68.win/bet/getallbet`, {})
				.then((res) => {
					setTotal(res.data.data);
				})
				.catch(() => setTotal(null));
		}
	}, [start]);

	return (
		<>
			<div className="app1 cskh">
				<div className="info_profile">
					<div className="cycle_bet">
						<span className="info_bet">Chăm Sóc Khách Hàng</span>
					</div>
				</div>
				{/*loading && (
					<div style={{ color: "#333", margin: "20px 0 0" }}>
						VUI LÒNG CHỜ TRONG GIÂY LÁT
					</div>
				)}
				<LiveChat license={16484763} />*/}
				<iframe src="https://direct.lc.chat/16484763"></iframe>
			</div>
			<Footer profile={profile} />
		</>
	);
}

export default CSKH;
